import React from 'react'

const FAQ_DATA = [
  {
    q: 'Pre-charter Consultation',
    a: (
      <>
        Here at Superior Yachting we believe that the little things can really take an experience
        from good to incredible, that’s why we arrange a consultation several weeks prior to your
        trip.
        <br />
        <br />
        It's not just dietary requirements we're looking for - we want to know what excursions
        you're interested in, your favourite wine and entertainment requests. Our goal is to exceed
        your expectations, and feeding this information back to our crew is how we manage to do just
        that!
      </>
    )
  },
  {
    q: 'Operating Expenses',
    a: (
      <>
        The Advance Provisioning Allowance (APA) is due at the same time of the final payment. Under
        MYBA terms, this sum is usually equivalent to 25% of the total charter fee. *This may be 30%
        in some cases.
        <br />
        <br />
        This is to cover the operating expenses of a charter such as fuel, drinks, food, port fees
        and ad hoc expenses. The APA will be given to the captain before the start of your trip. If,
        during the charter, the expenditure exceeds the sum already paid, additional cash funds will
        be required.
        <br />
        <br />
        All outstanding bills from the yacht charter should be settled in cash or by bank transfer
        before your final disembarkation. Of course, any remaining credit at the end of your charter
        will be reimbursed to you in full.
      </>
    )
  },
  {
    q: 'Payment Terms',
    a: (
      <>
        A deposit of 50% of the total charter fee is payable on signature of the MYBA Charter
        Agreement.
        <br />
        <br />
        The remaining 50%, plus an Advance Provisioning Allowance, together with any taxes,
        delivery/redelivery fees and any additionally agreed charges, is payable by bank transfer
        five weeks before the commencement of the charter.
      </>
    )
  },
  {
    q: 'Recreational Equipment',
    a: (
      <>
        All yachts offer a wide range of recreational equipment.
        <br />
        <br />
        If you have specific requirements, please let us know and we will discuss all the available
        options and pricing with you. In some cases, specific equipment will requite guests to
        obtain an operating license.
      </>
    )
  },

  {
    q: 'Internet',
    a: (
      <>
        All Superior Yachting's yachts have onboard internet, however in some locations the service
        can be limited. If this is something you require, we can of course make sure upgrades are
        made prior to your trip.
      </>
    )
  },
  {
    q: 'Insurance',
    a: (
      <>
        We strongly recommend that our clients take out Cancellation and Curtailment Insurance. This
        will cover unforeseen circumstances that might prevent or curtail their charter. All members
        of the charter party are covered by Personal Accident and Medical Insurance.
      </>
    )
  }
]

export default FAQ_DATA
