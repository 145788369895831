import React from 'react';
// import {graphql} from 'gatsby'
// import ProjectPreviewGrid from '../components/project-preview-grid'
// import SEO from '../components/seo'

import FAQ_DATA from '../resources/faqData';

import Container from '../hoc/container/Container';
import FAQ from '../components/faq/FAQ';

import style from './faq.module.css';
// import Layout from '../containers/layout'

// TODO: Need to test if the filter will work for nested values such as the weekly price inside the prices object.
// export const query = graphql`
//   query FAQPageQuery {
//     site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
//       title
//       subtitle
//       description
//       keywords
//     }
//   }
// `

const FAQPage = (props) => {
	return (
		<>
			{/* <SEO title={site.title} description={site.description} keywords={site.keywords} /> */}
			<section />

			<section className={style.container}>
				<h1>Answers to your Questions</h1>
				<p>
					Some answers to our most frequently asked questions.
					<br />
					Get in touch if you can’t find what you're looking for. We're more than happy to help!
				</p>
				<Container>
					{FAQ_DATA.map((faq, index) => {
						return <FAQ key={index} question={faq.q} answer={faq.a} />;
					})}
				</Container>
			</section>
			<div />
		</>
	);
};

export default FAQPage;
