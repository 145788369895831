import React, { useState, useRef } from "react";
import style from "./FAQ.module.css";
import arrowIcon from "../../assets/icons/faq-arrow.svg";
import questionIcon from "../../assets/icons/faq-question.svg";

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const answerText = useRef();

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  const openHeight = () => {
    return answerText.current.getBoundingClientRect().height + 70;
  };

  return (
    <div
      className={style.container}
      style={{ height: isOpen ? openHeight() : 70 }}
    >
      <div className={style.textContainer} onClick={handleClick}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className={style.questionIcon}
            src={questionIcon}
            alt="view the question icon"
          />
          <h4>{question}</h4>
        </div>

        <p ref={answerText}>{answer}</p>
      </div>

      <div className={style.iconContainer} onClick={handleClick}>
        <img
          src={arrowIcon}
          className={style.icon}
          style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
          alt="press to read the answer"
          description="F A Q control"
        />
      </div>
    </div>
  );
};

export default FAQ;
